// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllDataClientsChannels = createAsyncThunk('appUsers/getAllDataClientsChannels', async (id) => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/user/channels/all', { params: { id} })
  return response.data
})

export const getDataClientsChannels = createAsyncThunk('appUsers/getDataClientsChannels', async params => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/user/channels/data', { params})
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})

export const addUserToChannel = createAsyncThunk('appUsers/addUserToChannel', async (group, { getState }) => {
  //  option.client_id = getState()
    console.log("addPrivilgeToGroup")
    
    group.user_id = getState().users.selectedUser.id
    
    
    await axios.post('https://apiv3.opentlv.com/api/v3/user/add/user/channel', group)
    //.wait dispatch(getGroupPrivilegesData(getState().groupPrivileges.params))
  
    //await d
  })


export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllDataClientsChannels.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getDataClientsChannels.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
  }
})

export default appUsersSlice.reducer
