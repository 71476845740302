// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'

import users from '@src/views/apps/user/store'
import userGroup from '@src/views/apps/user/view/Group/store'
import userPrivileges from '@src/views/apps/user/view/Privileges/store'
import userSites from '@src/views/apps/user/view/Sites/store'
import usersZones from '@src/views/apps/user/view/Zones/store'

import userClients from '@src/views/apps/user/view/Clients/store'

import userChannels from '@src/views/apps/user/view/Channels/store'

//import users from '@src/views/apps/user/store'
import groupPrivileges from '@src/views/apps/user/group/store/storePrivileges'
import usersGroupPrivileges from '@src/views/apps/user/group/store/storesUsers'
import usersGroupes from '@src/views/apps/user/view/Groupes/store'
import players from '@src/views/apps/player/store'
import clients from '@src/views/apps/client/store'
import options from '@src/views/apps/client/store/storeOptions'
import optionsGroupe from '@src/views/apps/client/store/storeOptionsGroupe'
import catalogsGroupe from '@src/views/apps/client/store/storeCatalogsGroupe'
import playlistsGroupe from '@src/views/apps/client/store/storePlaylistsGroupe'
import creatorMedias from '@src/views/interface/medias/store'
import creatorData from '@src/views/interface/content/store'

//import creatorDataFiles from '@src/views/interface/content/store'
import creator from '@src/views/interface/creator/store'
import creatorEdit from '@src/views/interface/creator/edit/store'

import catalogs from '@src/views/apps/client/store/storeCatalogs'
import channels from '@src/views/apps/client/store/storeChannels'
import playlists from '@src/views/apps/client/store/storePlaylists'
import sitesClient from '@src/views/apps/client/store/storeSitesClient'
import optionsClient from '@src/views/apps/client/store/storeOptionsClient'
import channelsClient from '@src/views/apps/client/store/storeChannelsClient'
import catalogsClient from '@src/views/apps/client/store/storeCatalogsClient'
import playlistsClient from '@src/views/apps/client/store/storePlaylistsClient'
import configsClient from '@src/views/apps/client/store/storeConfigsClient'
import playersSite from '@src/views/apps/client/store/storePlayersSite'
import camerasSite from '@src/views/apps/client/store/storeCamerasSite'
import camerasMonitoring from '@src/views/cameras/store'
import camerasLogs from '@src/views/cameras/rapports/store'

import camera from '@src/views/cameras/camera/store'
import optionsSite from '@src/views/apps/client/store/storeOptionsSite'
import netconfigsSite from '@src/views/apps/client/store/storeNetconfigsSite'
import zonesSite from '@src/views/apps/client/store/storeZonesSite'
import schedulesSite from '@src/views/apps/client/store/storeSchedulesSite'
import catalogsSite from '@src/views/apps/client/store/storeCatalogsSite'
import playlistsSite from '@src/views/apps/client/store/storePlaylistsSite'
import hotlinksSite from '@src/views/apps/client/store/storeHotlinksSite'
import monitoring from '@src/views/apps/monitoring/store'
import monitoringLogs from '@src/views/apps/player/monitoring/store'

//import invoice from '@src/views/apps/invoice/store'
import ecommerce from '@src/views/apps/ecommerce/store'
//import dataTables from '@src/views/tables/data-tables/store'
import permissions from '@src/views/apps/roles-permissions/store'
import products from '@src/views/apps/hardware/storeProducts'
import hardwares from '@src/views/apps/hardware/storeHardwares'
import manufacters from '@src/views/apps/hardware/storeManufacters'
import groups from '@src/views/apps/user/storeGroups'
import sitesoptions from '@src/views/apps/client/store/storeOption'
import zonesOptions from '@src/views/apps/client/store/storeZone'
import catalogMedias from '@src/views/apps/client/store/storeMedias'
import catalogMediasPlayer from '@src/views/apps/client/viewCatalog/viewMedia/store'

import catalogLinks from '@src/views/apps/client/store/storeLinks'
import playerZones from '@src/views/apps/player/store/storeZones'
import playerCompnents from '@src/views/apps/player/store/storeComponents'
import clientOptionSites from '@src/views/apps/client/store/storeClientOptionSites'
import clientOptionZones from '@src/views/apps/client/store/storeClientOptionZones'
import channelPlaylists from '@src/views/apps/client/viewSites/viewChannel/store/storePlaylist'
import supports from '@src/views/interface/supports/store'
import mediasInterface from '@src/views/interface/support/store'
import campagnes from '@src/views/interface/support/storeCampagnes'
import events from '@src/views/interface/support/calendar/store'
import channelCatalogs from '@src/views/apps/client/viewSites/viewChannel/store/storeCatalogs'
import slotsPlaylist from '@src/views/apps/client/viewPlaylist/storeSlots'
import playerMonitoring from '@src/views/apps/client/store/storeMonitoring'
import zoneChannels from '@src/views/apps/client/viewSites/viewZone/store/storeChannelsZone'
import zoneOptions from '@src/views/apps/client/viewSites/viewZone/store/storeOptionsZone'
import zoneConfigs from '@src/views/apps/client/viewSites/viewZone/store/storeConfigsZone'
import zoneNetconfigs from '@src/views/apps/client/viewSites/viewZone/store/storeNetconfigsZone'
import configuration from '@src/views/apps/client/viewConfigurations/store'
import gares from '@src/views/sncf/store'
import groupes from '@src/views/groupes/store'
import pdv from '@src/views/interface/pdv/store'
//import groups from '@src/viesncf'


const rootReducer = {
  auth,
  users,
  userPrivileges,
  userGroup,
  userSites,
  groupPrivileges,
  usersGroupPrivileges,
  players,
  clients,
  options,
  catalogs,
  channels,
  playlists,
  monitoring,
  products,
  sitesClient,
  optionsClient,
  channelsClient,
  catalogsClient,
  playlistsClient,
  configsClient,
  playersSite,
  camerasSite,
  camerasMonitoring,
  camera,
  camerasLogs,
  optionsSite,
  sitesoptions,
  channelPlaylists,
  netconfigsSite,
  zonesSite,
  schedulesSite,
  catalogsSite,
  playlistsSite,
  hotlinksSite,
  zonesOptions,
  catalogMedias,
  catalogLinks,
  hardwares,
  manufacters,
  playerZones,
  playerCompnents,
  clientOptionSites,
  clientOptionZones,
  channelCatalogs,
  groups,
  navbar,
  layout,
  ecommerce,
  permissions,
  supports,
  mediasInterface,
  campagnes,
  events,
  slotsPlaylist,
  playerMonitoring, 
  zoneChannels,
  zoneOptions,
  zoneConfigs,
  zoneNetconfigs,
  userClients,
  userChannels,
  configuration,
  monitoringLogs,
  gares,
  groupes,
  usersGroupes,
  pdv,
  optionsGroupe,
  catalogsGroupe,
  playlistsGroupe,
  creatorMedias,
  creatorData,
  //creatorDataFiles,
  creator,
  creatorEdit,
  usersZones,
  catalogMediasPlayer
  

}

export default rootReducer
